import React, {Component} from "react";
import { Carousel } from "react-bootstrap";
import bwv from './Components_img/2.jpg';
import mers from './Components_img/3.jpg';
import mazda from './Components_img/4.jpg'

export default class CarouselBox extends Component {
            render(){
                return(
                      <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 h-80"
                            src="https://2.downloader.disk.yandex.ru/preview/f4f0c5cfb4ba41ece68ce93461d42a7a772b65f5f49a26ed61b105de75759a85/inf/-899sB_w7xzTzpU7T3ihCn7hWBrYljsJVTHtBK6Y2HLwkMuqpJnVAvU96vgxW5Coaax-8DHxO_y3RUXd6mYRFg%3D%3D?uid=1914375689&filename=106930807_3138382759575611_2254890568844082173_n_17855085125092584.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"
                            alt="logo"
                            />
                            <Carousel.Caption>
                                <h3>Подогрев руля</h3>
                                <p><strong>21 000 руб.</strong></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 h-80"
                            src={mazda}
                            alt="logo"
                            />
                            <Carousel.Caption>
                                <h3 bg="dark">Перетяжка салона</h3>
                                <p><strong>от 50 000 руб.</strong></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 h-80"
                            src={bwv}
                            alt="logo"
                            />
                            <Carousel.Caption>
                                <h3>Подогрев сидений</h3>
                                <p>от 25 00 руб.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                )
            }
}