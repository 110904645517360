import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import bwv from './Components_img/2.jpg';
import mers from './Components_img/3.jpg';
import mazda from './Components_img/4.jpg'

function GalleryContent({name}){
  return (
    <Col xs={6} md={4} className='bg-image hover-zoom mt-2'>
          <Image src={(name)} thumbnail  hover-zoom/>
        </Col>
  );
}

function Gallery() {
  return (
    <Container>
      <div className='HoverImg'>

      <h1 className='text-center'>Наши работы</h1>
      <Row>
        <GalleryContent 
          name = {"https://1.downloader.disk.yandex.ru/preview/9de1b52cfae1f92c39fea2ad2c2b44597c7cb8b6c6618ef6594334a5021fe564/infz/wRFe90nq1xrjYdgjX1NWXjA6BAgn0XDqmfRVOyEBOD8Ffhwy4UQqQxebSuVOKr4d9dvUkzOk96mR7-YOJakUxg%3D%3D?uid=1914375689&filename=167486493_443474030051643_6097123280863312358_n_18216562144038167%20%E2%80%94%20%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"}
        />
        <GalleryContent 
          name= {"https://4.downloader.disk.yandex.ru/preview/966259522657f14e386acdc721b95d2359d236f13b4d655bd7127cdf9daaadfd/inf/fjEID1ZKgvCInUWUATN-AhzRmYtWQnLBE8qvZQZyL6F9XWkvwwOlfE48nlhPSUmGGr43KuUT2c_9CrGhIsKF-w%3D%3D?uid=1914375689&filename=187264353_376571417043847_2556322566235885669_n_17892756524029081.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"}

        /><GalleryContent 
          name= {"https://2.downloader.disk.yandex.ru/preview/3075822cff84f9a5e121e483e0f243a6758de87d22d84216ba22565fd502a343/inf/_1Tfv-EAQC_HHJuev-g2oGSaey3MdfTH6rymENJdocAnXkP8xWLVe0Sh7NndQGtzvBIi3DllvmhLz9xrI43QqQ%3D%3D?uid=1914375689&filename=271866716_997587814178318_6045762055175377206_n_17961982102543618.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"}
        /><GalleryContent 
          name= {"https://2.downloader.disk.yandex.ru/preview/a3cba4c8716b8a045a6b30bdd0bc0e5aefa05fb402eff3d4ec3695ff92996d82/inf/JW8EdSB3kcpwBaweA8LJicsv7EsoDrBcfU_F3zjSYFiQqit-qD4m4SDhGlYfzh9PbSX1HlitN_g50GtWEfCm9w%3D%3D?uid=1914375689&filename=275529410_154310763694464_192032237750152584_n_17943494548879049.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"}
        /><GalleryContent 
          name= {"https://1.downloader.disk.yandex.ru/preview/95eac42c5e2fafb6238c3cd8a70945ee4cd2723731dbb241f23b21452412373e/inf/lkIWRSKo_lRRg09ipup62M7Zi4lm4w-3ABEn7foWVoUFM07f2Rlw-0AUzKYpZlCklg4TGnEIxMQ0abZT02WeTw%3D%3D?uid=1914375689&filename=IMG-20220510-WA0000.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"}
        />
        <GalleryContent 
          name= {bwv}
        />
      </Row>
      </div>
    </Container>
  );
}

export default Gallery;