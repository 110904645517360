import React, { Component } from "react";
import { Container, Form, Button } from "react-bootstrap";


export default class Home extends Component {
    render() {
        return (
            <div className="contactsPage">

                <Container className="d-flex justify-content-between">
                    <div className="contactsText">
                        <div>
                            <h1 className="text-center">Вы можете с нами связаться</h1>
                            <h3>Адрес:</h3>
                            <p>г. Реутов, Некрасова 41</p>
                            <h3>По номерам телефона:</h3>
                            <p>+8(925)477-28-93<br />+8(916)604-46-07</p>
                            <h3>Email:</h3>
                            <p>reboot.auot@yandex.ru</p>
                            <h3>Ближайшее метро:</h3>
                            <p>Новокосино, Новогиреево</p>
                        </div>
                        <div>
                            <h3>По вопросам сотрудничества и рекламы писать:</h3>
                            <p>email: reboot.auot@yandex.ru</p>
                        </div>
                    </div>
                    <div style={{ width: '500px' }}>
                        <h1 className="text-center">Напиши нам</h1>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Введите email" />
                                <Form.Text>
                                   
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Услуга</Form.Label>
                                <Form.Control as="textarea" rows="3" />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Label>Введите наименование услуги, которая вас заинтересовала</Form.Label>
                                <Form.Check type="checkBox" label="Нажми на меня" />
                                <Button variant="primary" type="submit">Заказать</Button>
                            </Form.Group>
                        </Form>
                    </div>
                </Container>
            </div>
        )
    }
}