import React, { Component } from "react";
import CarouselBox from "../Components/CarouselBox";
import { Button, Card, CardGroup, Container } from "react-bootstrap";
import Gallery from "../Components/gallery";
import { Modal } from "../Components/modal";





export default class Home extends Component {
    render() {
        return (
            <>
           
                <div className="homePage">

                    <CarouselBox />
                    <Container>
                        <h2 className="text-center m-4">Наши работы</h2>
                        <CardGroup className="m-3">
                            <Card className="m-3">
                                <Card.Img
                                    variant="top"
                                    src="https://disk.yandex.ru/i/25yRtwFkf7Xx8Q"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title>Подогрев руля</Card.Title>
                                    <Card.Text>21 000 руб.</Card.Text>
                                    <Button variant="primary" href="/contacts">Заказать</Button>
                                </Card.Body>
                            </Card>
                            <Card className="m-3">
                                <Card.Img
                                    variant="top"
                                    src="https://3.downloader.disk.yandex.ru/preview/46875b82e71fa05924aeaa25ba4a836457db9841a8e8c1183c68ef4973d59921/inf/rubfUHI_a1PuECnvQBBRf5LEss79v8J2YfixamHKeF7IckXvYm3nwGlwxYriVnPM-3_BFfVVc80sllgN3XjOUw%3D%3D?uid=1914375689&filename=106501134_297514598107002_7954124692214872902_n_17863115614936794.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"
                                />
                                <Card.Body className="text-center" >
                                    <Card.Title>Перетяжка салона</Card.Title>
                                    <Card.Text>от 40 000 руб.</Card.Text>
                                    <Button variant="primary" href="/contacts">Заказать</Button>
                                </Card.Body>
                            </Card>
                            <Card className="m-3" >
                                <Card.Img
                                    variant="top"
                                    src="https://2.downloader.disk.yandex.ru/preview/d672f28491b094af1edb3051c5bdab7c11559d4b5facdc52fe525f96c37b34a9/inf/fMFPjH49MYbfUXRhXjEMqFgNDOT-UgVo7UCiPqAAR1XdFtp5Ku3erCXV_EdtSUxuIqbT8yCoPZrJA9B0awSoqw%3D%3D?uid=1914375689&filename=106391808_282241869651190_7824408434892826134_n_17864638984936597.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"
                                />
                                <Card.Body className="text-center" >
                                    <Card.Title>Перетяжка потолка</Card.Title>
                                    <Card.Text>от 50 000 руб.</Card.Text>
                                    <Button variant="primary">Заказать</Button>
                                </Card.Body>
                            </Card>
                            <Card className="m-3">
                                <Card.Img
                                    variant="top"
                                    height="200px"
                                    src="https://3.downloader.disk.yandex.ru/preview/40b1f6883bcff311ba1248b5e91df1f40233b2a74a4883e93effdb59f732d5b5/inf/CFgfhOa82N4kHWxcDsxwKaE1GmnPShfJyGf_u-4K1eYglhhNquv3OHradqT5sB8NIpuWeEcQprimp093JSUwwg%3D%3D?uid=1914375689&filename=85151781_114868130093324_2697443722176049728_n_18052399504209537.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title>Шумоизоляция</Card.Title>
                                    <Card.Text>от 20 000 руб.</Card.Text>
                                    <Button variant="primary" href="/contacts">Заказать</Button>
                                </Card.Body>
                            </Card>

                        </CardGroup>
                    </Container>

                    <Gallery />

                </div>
            </>

        );
    }
}