import React, {Component} from "react";
import { Container, Row, Tab, Nav, Col } from "react-bootstrap";



export default class Home extends Component {
            render(){
                return(
                <div className="aboutPage">

                    <Container>
                        <Tab.Container id=";edt-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column mt-2">
                                      <Nav.Item>
                                        <Nav.Link eventKey="first">На рынке</Nav.Link>
                                        </Nav.Item> 
                                        <Nav.Item>
                                        <Nav.Link eventKey="second">Наши сотрудники</Nav.Link>
                                        </Nav.Item> 
                                        <Nav.Item>
                                        <Nav.Link eventKey="third">Достоинства</Nav.Link>
                                        </Nav.Item> 
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content className="mt-3">
                                        <Tab.Pane eventKey="first">
                                            <img src="https://avatars.mds.yandex.net/get-verba/216201/2a000001870896057c451b93243b9dd37dd1/cattouch"></img>
                                            <h1>20 лет</h1>
                                            <p>Мы работали со многими автомобилями в автосалонах и решили повысить клиенторентироватнность, поэтому встречайте Reboot auto.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                        <img src="https://2.downloader.disk.yandex.ru/preview/f4f0c5cfb4ba41ece68ce93461d42a7a772b65f5f49a26ed61b105de75759a85/inf/-899sB_w7xzTzpU7T3ihCn7hWBrYljsJVTHtBK6Y2HLwkMuqpJnVAvU96vgxW5Coaax-8DHxO_y3RUXd6mYRFg%3D%3D?uid=1914375689&filename=106930807_3138382759575611_2254890568844082173_n_17855085125092584.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=1914375689&tknv=v2&size=1606x917" className="w-50"></img>
                                            <h1>Наши специалисты</h1>
                                            <p>Имеют большой опыт в данной сфере и делают высококачесвенный продукт для вас.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <img src="https://avatars.mds.yandex.net/get-altay/11004775/2a0000018bc7d508f8b37cd6c785e031dc64/XXXL" className="w-50"></img>
                                            <h1>Наши достоинства</h1>
                                            <p>У нас быстрый сервис и вы можете оформить заказ, и осуществить его не выходя из дома!</p>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </div>
                )
            }
}