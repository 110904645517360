import React, { Component } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRipple,
  } from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";

export default class Home extends Component {
    render() {
        return (
            <div className="pricePage">
                <MDBContainer fluid className="my-5 text-center">
                    <h1 className="mt-4 mb-5">
                        <strong>Наши услуги</strong>
                    </h1>

                    <MDBRow>
                        <MDBCol md="12" lg="4" className="mb-4">
                            <MDBCard>
                                <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-6.userapi.com/impg/c857532/v857532287/21a03e/LsrYXv_Ef_4.jpg?size=2560x1707&quality=96&sign=2d855ace2318ad2d60b6d8de3f8cf799&type=album"
                                        fluid
                                        className="w-100"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div className="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-primary ms-2">New</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Перетяжка руля</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>эко-кожа</p>
                                    </a>
                                    <h6 className="mb-3">от 7 000 руб.</h6>
                                    
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBCard>
                                <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-78.userapi.com/impf/c854216/v854216728/189be/HQXw8qLF09A.jpg?size=1080x1029&quality=96&sign=aa413070831541e962a579e62e9463c7&type=album"
                                        fluid
                                        className="w-75"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div className="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-success ms-2">Eco</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Перетяжка руля</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>Натуральная кожа</p>
                                    </a>
                                    <h6 className="mb-3">от 9 000 руб.</h6>
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBCard>
                                <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-20.userapi.com/impf/c845217/v845217930/735a0/A6BxVYyF-M0.jpg?size=2560x1920&quality=96&sign=63e3a54f033530cda2522a6ed46e07b9&type=album"
                                        fluid
                                        className="w-100"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div className="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-danger ms-2">-10%</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Перетяжка салона</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>эко-кожа</p>
                                    </a>
                                    <h6 className="mb-3">
                                        <s>от 80 000 руб.</s>
                                        <strong className="ms-2 text-danger">от 70 000 руб.</strong>
                                    </h6>
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="12" lg="4" className="mb-4">
                            <MDBCard>
                                <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-12.userapi.com/impg/c857532/v857532287/21a178/ONQ_3gzYnuI.jpg?size=2560x1707&quality=96&sign=18910a449804ef5cb1cb2f2e78f07344&type=album"
                                        fluid
                                        className="w-100"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div className="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-success ms-2">Eco</span>
                                                    <span className="badge bg-danger ms-2">-10%</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Перетяжка салона</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>Натуральная кожа</p>
                                    </a>
                                    <h6 className="mb-3">
                                        <s>от 100 000 руб.</s>
                                        <strong className="ms-2 text-danger">от 90 000 руб.</strong>
                                    </h6>
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBCard>
                            <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-71.userapi.com/impg/c858024/v858024097/1cf7a3/MAOliBASZwQ.jpg?size=2560x1707&quality=96&sign=82cf41191f6de197ec32b904d7ac1b95&type=album"
                                        fluid
                                        className="w-100"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div class="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-primary ms-2">New</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Подогрев сидений</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>Доп. оборудование</p>
                                    </a>
                                    <h6 className="mb-3">25 000 руб.</h6>
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBCard>
                                <MDBRipple
                                    rippleColor="light"
                                    rippleTag="div"
                                    className="bg-image rounded hover-zoom"
                                >
                                    <MDBCardImage
                                        src="https://sun9-9.userapi.com/impg/c855020/v855020609/222d47/ErCM78iUqXM.jpg?size=2560x1707&quality=96&sign=616204d3f6643e74b0a2a35c2939892b&type=album"
                                        fluid
                                        className="w-100"
                                    />
                                    <a href="#!">
                                        <div className="mask">
                                            <div class="d-flex justify-content-start align-items-end h-100">
                                                <h5>
                                                    <span className="badge bg-primary ms-2">New</span>
                                                    <span className="badge bg-success ms-2">Eco</span>
                                                    <span className="badge bg-danger ms-2">-10%</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="hover-overlay">
                                            <div
                                                className="mask"
                                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                            ></div>
                                        </div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <a href="#!" className="text-reset">
                                        <h5 className="card-title mb-3">Подогрев руля</h5>
                                    </a>
                                    <a href="#!" className="text-reset">
                                        <p>Доп. оборудование</p>
                                    </a>
                                    <h6 className="mb-3">
                                        <s>23 000 руб.</s>
                                        <strong className="ms-2 text-danger">21 000 руб.</strong>
                                    </h6>
                                    <Button variant="primary"className="w-25" href="/contacts">Заказать</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

            </div>
        )
    }
}