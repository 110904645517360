import React, { Component } from "react";
import { Button, Container, Form, FormControl, Nav, Navbar } from "react-bootstrap";
import logo from './Components_img/logo192.png'
import { BrowserRouter as Router, Switch, Route, Link, Routes } from "react-router-dom";
import Home from '../Pages/Home'
import About from '../Pages/About'
import Contacts from '../Pages/Contacts'
import Price from '../Pages/Price'

export default class Header extends Component {

    render() {
        return (
            <>
                <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/">
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
                            <Nav className="mr-auto">
                                <h5><Nav.Link href="/">Reboot auto</Nav.Link></h5>
                                <Nav.Link href="/about">О нас</Nav.Link>
                                <Nav.Link href="/contacts">Контакты</Nav.Link>
                                <Nav.Link href="/price">Услуги</Nav.Link>
                            </Nav>
                            <Form inline className="text-light">
                                <p>+8(925)477-28-93</p>
                                <p>+8(916)604-46-07</p>
                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Router>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/about" element={<About />} />
                            <Route exact path="/contacts" element={<Contacts />} />
                            <Route exact path="/price" element={<Price />} />
                        </Routes>
                 
                </Router>
            </>
            
        );
    }
}