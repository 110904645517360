import React from 'react';
import Figure from 'react-bootstrap/Figure';
import { BrowserRouter as Router, Switch, Route, Link, Routes } from "react-router-dom";
import Home from '../Pages/Home'
import About from '../Pages/About'
import Contacts from '../Pages/Contacts'
import Price from '../Pages/Price'

function Footer() {

    return (
        <>
            <div className='mainFooter text-grey mt-3'>
                <div className='footer-middle'>
                    <div className='container'>
                        <div className='row'>
                            {/*column 1*/}
                            <div className='col-md-3 col-sm-6'>
                                <h4 className='text-white'>Наши контакты</h4>
                                <ul className='list-unstyled'>
                                    <li>Адрес: г. Реутов, Некрасова 41</li>
                                    <li>Телефон: 8(925)477-28-93</li>
                                    <li>Email: reboot.auto@yandex.ru</li>

                                </ul>
                            </div>
                            {/*column 2*/}
                            <div className=' col-sm-6 col-xs-5'>
                                <a href='/'><h4 className='text-white'>Reboot auto</h4></a>
                                <ul className='list-unstyled'>
                                    <li><a href='/about'>О нас</a></li>
                                    <li><a href='/contacts'>Контакты</a></li>
                                    <li><a href='/price'>Услуги</a></li>
                                </ul>
                            </div>
                            </div>
                            <div className='col-md-3 col-sm-6'>
                            <Figure>
                               <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=117292706871" width="400" height="300" frameborder="0"></iframe>
                            </Figure>
                        </div>
                        </div>
                        {/*Footer bootom*/}
                        <div className='footer-bottom mt-3'>
                            <p className='text-xs-center'>
                                &copy;{new Date().getFullYear()}Copyright Reboot auto Все права защищены.
                            </p>
                        </div>
                    </div>
                </div>
           

        </>
    );

}

export default Footer;

